<template>
  <Layout tituloPagina="Sistema | Cuenta | Cambiar de plan VPN">
    <div class="row">
      <div class="col-lg-3" v-show="estado_modulo == true">
        <div class="card">
          <div class="card-header bg-success justify-content-between d-flex align-items-center">
            <h4 class="card-title text-white">Plan VPN actual</h4>
            <div>
              <span class="badge bg-info bg-gradient">Activo</span>
            </div>
          </div>
          <div class="card-body" style="min-height: 509px;">
            <h1 class="text-center">
              <strong>Plan {{plan_vpn_contratado.nombre}}</strong>
            </h1>
            <br>
            <table class="table">
              <tbody class="text-center">
                <tr>
                  <td>Credenciales</td>
                  <td>{{plan_vpn_contratado.max_credenciales != -1 ? 'hasta '+plan_vpn_contratado.max_credenciales+' usuarios' : 'Ilimitado'}}</td>
                </tr>
                <tr>
                  <td>Precio mensual</td>
                  <td>${{plan_vpn_contratado.precio_mensual}} USD</td>
                </tr>
                <tr>
                  <td>Precio anual</td>
                  <td>
                    ${{plan_vpn_contratado.precio_anual}} USD <br>
                    <strong>{{costoAnualPocentaje(plan_vpn_contratado.precio_mensual, plan_vpn_contratado.precio_anual)}}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="text-center">
              <p>
                <span class="h3">
                  <strong>Precio ${{plan_vpn_contratado.precio_mensual}} USD</strong>
                </span><br>
                Costo mensual
              </p>
            </div>
            <br>
            <div class="text-center">
              <button
                class="btn w-200"
                :class="estado_modulo != true ? 'btn-success' : 'btn-danger'"
                @click="actDescEstModulo()"
              >
                <i class="fa" :class="estado_modulo != true ? 'mdi-check-bold' : 'mdi-cancel'"></i>
                {{estado_modulo != true ? 'Activar modulo' : 'Desactivado modulo'}}
              </button>
            </div>
            <hr>
            <div class="text-center">
              <p><strong>Perido actual: {{formatoFecha(ultima_suscripcion.inicio)}} a {{formatoFecha(ultima_suscripcion.fin)}}</strong></p>
            </div>
          </div>
        </div>
      </div>
      <div :class="estado_modulo == true ? 'col-lg-9' : 'col-lg-12'">
        <div class="row">
          <template v-for="plan_vpn in planes_vpn">
            <div :key="plan_vpn.id" class="col-md-3" v-if="true">
              <div class="card">
                <div class="card-header bg-info justify-content-between d-flex align-items-center">
                  <h4 class="card-title text-white">{{plan_vpn.nombre}}</h4>
                  <div>
                    <i class="mdi mdi-bookmark fa-lg text-white"></i>
                  </div>
                </div>
                <div class="card-body">
                  <h5 class="text-center">
                    <strong>Caracteristicas del plan</strong>
                  </h5>
                  <br>
                  <table class="table">
                    <tbody class="text-center">
                      <tr>
                        <td style="width:50%;">Credenciales</td>
                        <td>{{plan_vpn.max_credenciales != -1 ? 'hasta '+plan_vpn.max_credenciales : 'Ilimitado'}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="text-center">
                    <p>
                      <span class="h4">
                        <strong>Precio ${{plan_vpn.precio_mensual}} USD</strong>
                      </span><br>
                      Costo mensual
                    </p>
                  </div>
                  <br>
                  <div class="text-center">
                    <p>
                      <span class="h4">
                        <strong>Precio ${{plan_vpn.precio_anual}} USD</strong>
                      </span><br>
                      {{costoAnualPocentaje(plan_vpn.precio_mensual, plan_vpn.precio_anual)}}
                    </p>
                  </div>
                  <div class="text-center">
                    <button
                      class="btn btn-outline-link text-success"
                      @click="caracteristicasvpn(plan_vpn.id)">
                      Ver todas las caracteristicas
                    </button>
                  </div>
                  <br>
                  <router-link :to="{ name: 'actualizarPlanVpn', params:{ id_plan: plan_vpn.id} }">
                    <div class="p-2 m-0 rounded text-center bg-soft-dark">
                      <span class="h4">
                        <strong>
                          {{estado_modulo ? 'Cambiar' : 'Activar'}} plan vpn
                        </strong>
                      </span>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="text-right pb-4" style="min-width: 220px;">
      <button class="btn btn-secondary" @click="$router.go(-1)">
        <i class="mdi mdi-chevron-left"></i>
        Atras
      </button>
    </div>
    <mdl-caracteristicas-vpn ref="mdlCaracteristicasVpn"></mdl-caracteristicas-vpn>
    <br>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import MdlCaracteristicasVpn from "./MdlCaracteristicasVpn.vue"
import CuentaSrv from "@/services/CuentaSrv.js"
import moment from 'moment'

export default {
  name: 'Adm3ArgusblackGestionVpn',
  components: { Layout, MdlCaracteristicasVpn },
  data() {
    return {
      estado_modulo: false,

      plan_vpn_contratado: {
        id: null,
        descripcion: "",
        max_credenciales: 0,
        nombre: "",
        precio_anual: 0,
        precio_mensual: 0
      },
      planes_vpn: [],

      ultima_suscripcion: {
        estado: 0,
        fin: "",
        id: null,
        id_cliente: null,
        id_empresa: null,
        id_plan_hotspot: null,
        id_plan_inventario: null,
        id_plan_isp: null,
        id_plan_vpn: null,
        inicio: "",
        renovar: 0,
        tipo_suscripcion: 0
      }
    }
  },

  created: function() {
    var self = this

    self.cargarEstado()
    self.refrescarPlanesVpn()
  },
  methods: {
    actDescEstModulo: function() {
      var self = this

      if(self.estado_modulo != 1) {
        CuentaSrv.activarModulo('vpn').then(response => {
          iu.msg.success('Modulo vpn activado correctamente')
          self.cargarEstado()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo activar el modulo vpn'
          }
          iu.msg.error(mensaje)
          console.log(error)
        })
      } else {
        CuentaSrv.desactivarModulo('vpn').then(response => {
          iu.msg.success('Modulo vpn desactivado correctamente')
          self.cargarEstado()
        }).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudo desactivar el modulo vpn'
          }
          iu.msg.error(mensaje)
          console.log(error)
        })
      }
    },
    
    caracteristicasvpn: function(idPlan) {
      this.$refs.mdlCaracteristicasVpn.mostrar(idPlan, "planes", this.estado_modulo)
    },

    cargarEstado: function() {
      var self = this

      CuentaSrv.estadoModulo('vpn').then(response => {
        self.estado_modulo = response.data

        if(self.estado_modulo == true) self.cargarPlanContratado()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPlanContratado: function (){
      var self = this

      CuentaSrv.moduloPlanContratadoJSON('vpn').then(response => {
        self.plan_vpn_contratado = response.data

        self.suscripcion()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    costoAnualPocentaje: function(precio_mensual, precio_anual){
      if(precio_anual < 0) return ''

      let total = precio_mensual * 12
      let porcentaje = ((total - precio_anual) * 100) / total

      if(porcentaje < 0) return ''

      return `Costo anual (incluye ${parseInt(porcentaje)}% de descuento)`
    },

    formatoFecha(mt, formato = 'DD/MM/YYYY') {
      return moment(mt).locale('es-mx').format(formato)
    },

    refrescarPlanesVpn: function() {
      var self = this

      CuentaSrv.moduloPlanesDisponiblesJSON('vpn').then(response => {
        self.planes_vpn = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'no se puedo cargar los planes vpn disponibles'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    suscripcion() {
      var self = this

      CuentaSrv.suscripcionJSON().then(response => {
        self.ultima_suscripcion = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>